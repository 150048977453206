<template>
    <div class="pa-10-20">
        <h6>{{ program.name }}</h6>

        <div class="">
            <div class="justify-space-between">
                <div class="flex-1 bg-white mr-10">
                    <div class="pa-10">
                        <h6 class="under-line-identify">신청 구분</h6>
                        <table class="table th-left td-left">
                            <col class="width-120px">
                            <col class="width-50-120">
                            <col class="width-120px">
                            <col class="width-50-120">
                            <tbody>
                            <tr>
                                <th>영업단 구분 <span class="color-red">*</span></th>
                                <td>
                                    <select
                                        v-model="item.type"
                                        class="input-box pa-5-10 "
                                        @change="resetUpper"
                                        disabled
                                    >
                                        <template
                                            v-for="(agency, index) in codes.agency_type"
                                        >
                                            <option
                                                v-if="index > 0 && index <= codes.agency_type.length - 2"
                                                :key="'agency_' + index"
                                                :value="agency.code"
                                            >{{ agency.name }}
                                            </option>
                                        </template>
                                    </select>
                                </td>
                                <th>상위 영업단 <span class="color-red">*</span></th>
                                <td>
                                    <div class="justify-space-between">
                                        <div class="flex-column justify-center">
                                            {{ item.upperName ? item.upperName : upper_name }}
                                        </div>
                                        <button
                                            v-if="item.type > 1"
                                            v-show="false"
                                            class="bg-identify pa-5-10 vertical-middle"
                                            @click="getAgencyList"
                                        >영업단 조회
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>아이디</th>
                                <td>{{ item.id }}</td>
                                <th>비밀번호</th>
                                <td class="text-right">
                                    <div class="justify-space-between">
                                        <span class=""></span>
                                        <button
                                            class="bg-identify pa-5-10"
                                            @click="isPassword"
                                        >임시 비밀번호 발급
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>등록일자</th>
                                <td>{{ item.date }}</td>
                                <th>계정상태</th>
                                <td>
                                    <div class="justify-space-between">
                                        <span class="flex-column justify-center">{{ state_name }}</span>
                                        <button
                                            class="bg-identify pa-5-10 vertical-middle"
                                            @click="isStatus"
                                        >계정 상태 변경
                                        </button>
                                    </div>
                                </td>
                            </tr>

                            <tr v-if="item.type == 1">
                                <th>배달·월세 MID</th>
                                <td colspan="3">
                                    <div class="justify-start">
                                        <span class="justify-center mr-20">{{ item.billKeyPaymentId ? '사용중' : '미사용' }}</span>
                                        <button
                                            class="pa-5-10 bg-identify"
                                            @click="isModalMid"
                                        >설정
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="item.type == 1">
                                <th>핵토 SID</th>
                                <td class="">

                                    <div class="justify-space-between">
                                        <span class="justify-center mr-20">{{ item.sid ? item.sid : '-' }}</span>
                                        <button
                                            class="pa-5-10 bg-identify"
                                            @click="isModalSid"
                                        >설정
                                        </button>
                                    </div>
                                </td>
                                <th>핵토 SID KEY</th>
                                <td class="">
                                    <div class="justify-space-between">
                                        <span class="justify-center mr-20">-</span>
                                        <button
                                            class="pa-5-10 bg-identify"
                                            @click="isModalSid"
                                        >설정
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr
                                v-if="item.type == 1"
                            >
                                <th>정산 주기 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <div class="flex-row justify-space-between items-center">
                                        <div class="mr-20">
                                            <input type="radio" id="cycle_month" value="MONTH" v-model="item.settlementCycle">
                                            <label for="cycle_month" class="ml-5 mr-20">한달</label>
                                            <input type="radio" id="cycle_week" value="WEEK" v-model="item.settlementCycle">
                                            <label for="cycle_week" class="ml-5">일주일</label>
                                        </div>
                                        <div class="text-right">
                                            <p>총판 하위 영업점의 경우 총판 정상주기와 동일합니다.</p>
                                            <p>등록 후 변경이 불가능 합니다.</p>
                                        </div>
                                    </div>
                                </td>

                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="flex-1">
                    <div class="bg-white pa-10">
                        <h6 class="under-line-identify">사업자 정보</h6>
                        <table class="table th-left td-left">
                            <col width="120px">
                            <col width="auto">
                            <col width="120px">
                            <col width="auto">
                            <tbody>
                            <tr>
                                <th>사업자 구분 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <label
                                        class="cont-radio"
                                        v-for="code in codes.P02"
                                        :key="code.TOTAL_CODE">
                                        <input
                                            v-model="item.businessType"
                                            type="radio"
                                            :value="code.TOTAL_CODE"
                                            class="box"
                                        />
                                        {{ code.code_name }}
                                    </label>
                                </td>
                            </tr>
                            <tr
                                v-if="item.businessType == '1'"
                            >
                                <th>사업자등록번호 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <input
                                        type="number"
                                        v-model="item.businessNumber"
                                        :ruels="[rules.max(item, 'businessNumber', 15)]"
                                        class="input-box-inline"
                                        placeholder="사업자 등록 번호"
                                    />
                                </td>
                            </tr>
                            <tr
                                v-if="item.businessType == '0'"
                            >
                                <th>사업자등록번호 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        type="number"
                                        v-model="item.businessNumber"
                                        :ruels="[rules.max(item, 'businessNumber', 15)]"
                                        class="input-box"
                                        placeholder="사업자 등록 번호"
                                    />
                                </td>
                                <th>법인등록번호 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        type="number"
                                        v-model="item.corporationNumber"
                                        :ruels="[rules.max(item, 'corporationNumber', 15)]"
                                        class="input-box"
                                        placeholder="법인 등록 번호"
                                    />
                                </td>
                            </tr>
                            <tr
                                v-if="item.businessType == '2'"
                            >
                                <th>주민등록 번호 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <input
                                        v-model="item.frontSSN"
                                        type="number"
                                        :ruels="[rules.max(item, 'frontSSN', 6)]"
                                        class="input-box-inline mr-10"
                                        placeholder="주민등록 앞번호"
                                    />
                                    <input
                                        v-model="item.backSSN"
                                        type="password"
                                        maxlength="7"
                                        class="input-box-inline"
                                        placeholder="주민등록 뒷번호"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>상호명 <span class="color-red">*</span></th>
                                <td colspan="3">
                                    <input
                                        type="text"
                                        v-model="item.shopName"
                                        maxlength="50"
                                        class="input-box"
                                        placeholder="상호명을 입력하세요"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>대표자 성명 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        type="text"
                                        v-model="item.name"
                                        maxlength="20"
                                        class="input-box"
                                        placeholder="대표자 성명을 입력하세요"
                                    />
                                </td>
                                <th>휴대폰 번호</th>
                                <td>
                                    <input
                                        type="number"
                                        v-model="item.hp"
                                        :ruels="[rules.max(item, 'hp', 12)]"
                                        class="input-box"
                                        placeholder="대표자 휴대폰번호를 입력하세요"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>업태</th>
                                <td class="si-desc"
                                    style="margin-right: 10px;">
                                    <input
                                        type="text"
                                        v-model="item.businessCondition"
                                        maxlength="20"
                                        class="input-box"
                                        placeholder="업태를 입력하세요"
                                    />
                                </td>
                                <th>업종</th>
                                <td>
                                    <input
                                        type="text"
                                        v-model="item.businessLine"
                                        maxlength="20"
                                        class="input-box"
                                        placeholder="업종을 입력하세요"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>사업장 주소 <span class="color-red">*</span></th>
                                <td colspan="3">

                                    <input
                                        v-model="item.zipCode"

                                        class="input-box-inline mr-10"
                                        type="text" placeholder="우편번호"
                                        readonly

                                        @click="daumPost('company')"
                                    >

                                    <button
                                        class="box pa-5-10 bg-identify"
                                        @click="daumPost('company')"
                                    >주소 검색
                                    </button>

                                    <input
                                        v-model="item.address"

                                        class="input-box full-width mt-10" type="text" placeholder="주소"
                                        readonly

                                        @click="daumPost('company')"
                                    >
                                    <input
                                        v-model="item.addressDetail"

                                        class="input-box full-width mt-10" type="text" placeholder="상세주소를 입력하세요."
                                        maxlength="50"
                                    >
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <div class="justify-space-between">
                <div class="flex-1 mr-10">
                    <div class="mt-10 bg-white pa-10">
                        <div
                            class="under-line-identify justify-space-between"
                        >
                            <h6>서비스 이용 조건</h6>
                            <span class="flex-column justify-end size-px-12">수수료는 소수점 두자리, 부가세는 별도입니다.</span>
                        </div>

                        <table class="table th-left td-left">
                            <col width="130px"/>
                            <col width="auto"/>
                            <tbody>
                            <tr v-if="item.type == 1">
                                <th>배달비 지급 주기 <span class="color-red">*</span></th>
                                <td>
                                    <input type="radio" id="1" value="1" v-model="item.quickType"><label for="1"
                                                                                                         class="ml-5 mr-20">배달비
                                    익일 지급</label>
                                    <input type="radio" id="0" value="0" v-model="item.quickType"><label for="0"
                                                                                                         class="ml-5">배달비
                                    즉시 지급</label>
                                </td>
                            </tr>
                            <tr v-if="item.type == 1">
                                <th>배달비 정산 방식 <span class="color-red">*</span></th>
                                <td>
                                    <input type="radio" id="auto" :value="true" v-model="item.autoSettlementFlag">
                                    <label for="auto" class="ml-5 mr-20">
                                        자동 정산
                                    </label>
                                    <input type="radio" id="manual" :value="false" v-model="item.autoSettlementFlag" :disabled="item.quickType === '0'">
                                    <label for="manual" class="ml-5">
                                        수기 정산
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th>서비스 수수료 <span class="color-red">*</span></th>
                                <td>
                                    <div class="justify-space-between">
                                        <div class="flex-1">
                                            배달비
                                            <input
                                                v-model="item.quickFee" class="input-box-inline width-50px text-right"
                                                @change="checkFee('QUICK')"
                                                :rules="[rules.demical(item, 'quickFee', { min: 2, max: 2})]"/> %
                                        </div>
                                        <div class="flex-1">
                                            월세
                                            <input
                                                v-model="item.monthlyFee" class="input-box-inline width-50px text-right"
                                                @change="checkFee('MONTHLY')"
                                                :rules="[rules.demical(item, 'monthlyFee', { min: 2, max: 2})]"/> %
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>수기결제 수수료 <span class="color-red">*</span></th>
                                <td>
                                    <div class="justify-space-between">
                                        <div class="flex-1">
                                            D-Day 정산
                                            <input
                                                v-model="item.nonInstantFee" class="input-box-inline width-50px text-right"
                                                @change="checkFee('D_DAY')"
                                                :rules="[rules.demical(item, 'nonInstantFee', { min: 2, max: 2})]"/> %
                                        </div>
                                        <div class="flex-1">
                                            즉시 정산
                                            <input
                                                v-model="item.instantFee" class="input-box-inline width-50px text-right"
                                                @change="checkFee('D_0')"
                                                :rules="[rules.demical(item, 'instantFee', { min: 2, max: 2})]"/> %
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>정산기준</th>
                                <td class="text-left">위 에이전트는 PG사와의 계약관계에 근거하여 정산금을 결제대행 서비스를 통하여 지급받는 것에 동의합니다.</td>
                            </tr>
                            <tr>
                                <th>정산주기</th>
                                <td class="text-left">영업일 기준 / 월 정산</td>
                            </tr>
                            <tr>
                                <th>특약사항</th>
                                <td>
                                    <ol class="text-left line-height-200">
                                        <li>1. 이행보험증 증권은 서비스 이용 신청일로부터 7일 이내에 제출한다.</li>
                                        <li>2. 정산 후 취소 시 발생하는 모든 제반 비용은 상기 에이전트가 책임을 진다.</li>
                                        <li>3. 단말기 분실 및 파손 시 통신비 1년 약정에 대한 비용은 상기 에이전트가 책임을 진다.</li>
                                        <li>4. 상기 에이전트는 하위 가맹점의 부실채권에 대해 공동의 책임을 진다.</li>
                                        <li>5. 상기 에이전트는 본사와의 계약기간동안 모집된 가맹점에 관하여 상호간의 협의없이 타결제 대항사와 에이전트 계약을 할 수 없다.
                                        </li>
                                    </ol>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div class="flex-1">
                    <div class="mt-10 bg-white pa-10">
                        <h6>정산 계좌 정보</h6>
                        <table class="table th-left td-left">
                            <col width="120px"/>
                            <col width="auto"/>
                            <col width="120px"/>
                            <col width="auto"/>
                            <tbody>
                            <tr>
                                <th>은행 <span class="color-red">*</span></th>
                                <td>
                                    <select
                                        v-model="item.bankIdx"
                                        class="input-box"
                                    >
                                        <option value="">선택하세요</option>
                                        <option
                                            v-for="(code, index) in codes.P13"
                                            :key="'bank_' + index"
                                            :value="code.idx"
                                        >{{ code.name }}
                                        </option>
                                    </select>
                                </td>
                                <th>계좌번호 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        v-model="item.account"
                                        type="number"
                                        class="input-box full-width"
                                        :rules="[rules.max(item, 'bank_name', 25)]"
                                        placeholder="정산 계좌 번호"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>예금주 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        v-model="item.accountHolder"
                                        class="input-box full-width"
                                        placeholder="정산 계좌 예금주"
                                        maxlength="15"
                                    />
                                </td>
                                <th>이메일 <span class="color-red">*</span></th>
                                <td>
                                    <input
                                        v-model="item.email"
                                        type="email"
                                        class="input-box full-width"
                                        placeholder="세금계산서 발행 이메일"
                                        maxlength="50"
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="mt-10 bg-white pa-10">
                        <h6>부가정보</h6>
                        <table class="table th-left td-left full-width">
                            <col width="120px"/>
                            <col width="auto"/>
                            <tbody>
                            <tr>
                                <th>초기비용</th>
                                <td>
                                    <div> 보증보험 증권
                                        <input
                                            v-model="item.performanceBondAmount"
                                            class="box width-10 pa-5-10 text-right"
                                            type="number"
                                            :rules="[rules.max(item, 'performanceBondAmount', 7)]"
                                        /> 만원
                                    </div>
                                    <p class="mt-10">기타 관리비: 단말기 및 통신비는 해당 PG사의 정책에 따라 별도 부가됨</p>
                                </td>
                            </tr>
                            <tr>
                                <th>계약서류</th>
                                <td>
                                    <label class="box pa-5-10 vertical-middle mr-10 mb-10">
                                        <input
                                            v-show="false"
                                            type="file"
                                            multiple
                                            accept="image/*"
                                            @change="setFile"
                                        />
                                        <v-icon
                                            small
                                        >mdi mdi-image-multiple
                                        </v-icon>
                                        첨부파일
                                    </label>
                                    <div class="mt-10">
                                        <button
                                            v-for="(file, index) in item.attachments"
                                            :key="'file_delete_' + index"
                                            @click="isDeleteNewFile(index)"
                                            class="btn-default pa-5-10 mr-10 mb-10"
                                        >{{ file.name }}
                                            <v-icon class="color-red" small>mdi mdi-close</v-icon>
                                        </button>
                                    </div>
                                    <template
                                        v-for="(file, index) in files"
                                    >
                                        <button
                                            :key="'file_' + index"
                                            class="btn-default pa-5-10 mb-10"
                                            @click="download(file)"
                                        >{{ file.originFileName }}
                                            <v-icon small>mdi mdi-download</v-icon>
                                        </button>
                                        <button
                                            :key="'file_delete_' + index"
                                            @click="isDeleteFile(index)"
                                            class="box pa-5 mr-10 mb-10"
                                        >
                                            <v-icon class="color-red" small>mdi mdi-close</v-icon>
                                        </button>
                                    </template>
                                </td>
                            </tr>
                            <tr v-if="item.type == 1">
                                <th>로고이미지</th>
                                <td>
                                    <label class="box pa-5-10 vertical-middle mr-10 mb-10">
                                        <input
                                            v-show="false"
                                            type="file"
                                            accept="image/*"
                                            @change="setLogoFile"
                                        />
                                        <v-icon
                                            small
                                        >mdi mdi-image-multiple
                                        </v-icon>
                                        첨부파일
                                    </label>
                                    <div class="mt-10">
                                        <button
                                            v-if="item.logoImage[0]"
                                            @click="item.logoImage = []"
                                            class="btn-default pa-5-10 mr-10 mb-10"
                                        >{{ item.logoImage[0].name }}
                                            <v-icon class="color-red" small>mdi mdi-close</v-icon>
                                        </button>
                                    </div>
                                    <template
                                        v-if="logo_files"
                                    >
                                        <button
                                            class="btn-default pa-5-10 mb-10"
                                            @click="download(logo_files[0])"
                                        >{{ logo_files[0].name }}
                                            <v-icon small>mdi mdi-download</v-icon>
                                        </button>
                                        <button
                                            @click="modifyLogoFile"
                                            class="box pa-5 mr-10 mb-10"
                                        >
                                            <v-icon class="color-red" small>mdi mdi-close</v-icon>
                                        </button>
                                    </template>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="mt-10 bg-white pa-10"
            v-if="history_list.length > 0"
        >
            <h6 class="under-line-identify">수정 이력</h6>
            <ul
                v-if="history_list.length > 0"
            >
                <li
                    v-for="(history, index) in history_list"
                    :key="'history_' + index"

                >{{ history.date }} {{ history.cotnent }}
                </li>
            </ul>
            <div
                v-else
                class="pa-50 text-center"
            >No Data
            </div>
        </div>

        <div
            class="ptb-30 justify-center"
        >
            <button
                class="bg-identify pa-10-20 mr-10"
                @click="save"
            >저장
            </button>
            <button
                class="bg-ddd pa-10-20"
                @click="toList"
            >목록
            </button>
        </div>

        <Modal
            :is_modal="is_modal"
            :option="modal_option"
            :top="true"
            :bottom="true"

            title="임시 비밀번호 발급"
            content="임시 비밀번호를 발급하고 등록된 연락처로 전송하시겠습니까?"
            width="360px"
            content_class="pa-30-10 text-left"

            @close="clear"
            @click="doPassword"
            @cancel="clear"
        >
        </Modal>

        <Modal
            :is_modal="is_modal2"
            :option="modal_option2"

            title="영업점 계정상태 변경"
            :top="true"
            :bottom="true"
            width="360px"

            @close="clear"
            @click="doStatus"
            @cancel="clear"
        >
            <div
                slot="modal-content"
            >

                <select
                    v-model="item.state"
                    class="full-width pa-10"
                >
                    <option
                        v-for="(status, index) in codes.status_agency"
                        :key="'status_' + index"
                        :value="status.code"
                    >{{ status.name }}
                    </option>
                </select>

                <textarea
                    class="mt-10"
                    v-model="item.reason"
                    placeholder="메모"
                ></textarea>

            </div>
        </Modal>

        <Modal
            :is_modal="is_modal3"
            :option="modal_option3"
            @close="clear"
        >
            <div
                slot="modal-bottom"
                class="justify-space-between"
            >
                <button
                    class="btn flex-1 btn-identify"
                    @click="doFileDelete"
                >확인
                </button>
                <button
                    class="btn flex-1 btn-default"
                    @click="clear"
                >취소
                </button>
            </div>
        </Modal>

        <DaumPost
            :overlay="is_post"
            :config="daum_config"
            @callBack="addPost"
        ></DaumPost>

        <Modal
            :is_modal="is_modal4"
            :option="modal_option4"
            :top="true"
            :bottom="false"

            title="상위 영업단 조회"
            height="550px"

            @close="clear"
        >
            <AgencySearchList
                slot="modal-content"

                :item_type="item.type"
                :user="user"

                @click="setUpper"
                @cancel="clear"
                @next="nextUpper"
            ></AgencySearchList>
        </Modal>

        <Modal
            :is_modal="is_modal5"
            :option="modal_option5"
            @close="clear"
        >
            <div slot="modal-content">
                <img :src="item_att" alt="img" style="object-fit: cover; max-width: 600px; max-height: 800px;"/>
            </div>
        </Modal>
        <Modal
            :is_modal="modal_mid"
            :top="true"
            :bottom="true"
            :slot_bottom="true"

            title="배달·월세 MID 설정"
            width="420px"

            @close="clear"
        >
            <div
                slot="modal-content"
            >
                <table
                    class="table th-left top-line-identify"
                >
                    <col width="130px"/>
                    <col width="auto"/>
                    <tbody>
                    <tr
                        v-if="item.type == 1"
                    >
                        <th>배달·월세 MID</th>
                        <td class="">
                            <input
                                v-model="item.billKeyPaymentId"
                                class="input-box full-width"
                                placeholder="배달·월세 MID"
                                max="50"
                            />
                        </td>

                    </tr>

                    <tr
                        v-if="item.type == 1"
                    >
                        <th>배달·월세 MID KEY</th>
                        <td class="">
                            <input
                                v-model="item.billKeyPaymentPassword"
                                type="password"
                                class="input-box full-width"
                                placeholder="배달·월세 MID KEY"
                                max="50"
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div class="mt-20 text-left"><label><input type="checkbox"/> 배달·월세 MID 설정을 변경하시겠습니까?</label></div>
            </div>
            <div
                slot="modal-bottom"
                class="pa-10"
            >
                <button
                    class="bg-identify-outline mr-10 pa-10"
                    @click="isDeleteMid"
                >MID 삭제
                </button>
                <button
                    class="bg-identify mr-10 pa-10"
                    @click="doSaveMid"
                >수정
                </button>
                <button
                    class="bg-bbb pa-10"
                    @click="clear"
                >닫기
                </button>
            </div>
        </Modal>
        <Modal
            :is_modal="modal_delete_mid"
            :top="true"
            :bottom="true"

            title="배달·월세 MID 삭제"
            content="사용중인 배달·월세 MID 정보를 삭제하시겠습니까?"
            width="380px"
            content_class="ptb-30"

            @clear="clear"
            @click="doDeleteMid"
            @cancel="clear"
        ></Modal>

        <Modal
            :is_modal="modal_sid"
            :top="true"
            :bottom="true"
            :slot_bottom="true"

            title="핵토 SID 설정"
            width="420px"

            @close="clear"
        >
            <div
                slot="modal-content"
            >
                <table
                    class="table th-left top-line-identify"
                >
                    <col width="130px"/>
                    <col width="auto"/>
                    <tbody>

                    <tr
                        v-if="item.type == 1"
                    >
                        <th>핵토 SID</th>
                        <td class="">
                            <input
                                v-model="item.sid"
                                class="input-box full-width"
                                placeholder="핵토 SID"
                                max="50"
                            />
                        </td>
                    </tr>
                    <tr
                        v-if="item.type == 1"
                    >
                        <th>핵토 SID KEY</th>
                        <td class="">
                            <input
                                v-model="item.key"
                                class="input-box full-width"
                                placeholder="핵토 SID KEY"
                                max="50"
                            />
                        </td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <div
                slot="modal-bottom"
                class="pa-10"
            >
                <button
                    class="bg-identify pa-10"
                    @click="postSid"
                >저장
                </button>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from "@/components/Modal";
import DaumPost from "@/components/Daum/DaumPost";
import AgencySearchList from "@/view/Agency/AgencySearchList";

export default {
    name: 'AgencyDetail'
    ,
    components: {AgencySearchList, DaumPost, Modal},
    data: function () {
        return {
            user: [],
            program: {
                name: '영업점 상세정보'
                , top: true
                , title: true
                , bottom: false
            }
            , fee_base: 4
            , item: {
                fee: 0,
            }
            , item_origin: {}
            , is_modal: false
            , modal_option: {}
            , is_modal2: false
            , modal_option2: {}
            , is_modal3: false
            , modal_option3: {
                title: '첨부파일 삭제'
                , top: true
                , bottom: true
                , content: '해당 첨부파일을 삭제하시겠습니까?'
                , content_class: 'pa-30-10 text-left'
                , width: '360px'
            }
            , is_modal4: false
            , modal_option4: {}
            , is_modal5: false
            , modal_option5: {
                title: '첨부파일'
                , top: true
                , bottom: false
            }
            , is_post: false
            , daum_config: {
                width: '360px'
            }
            , items_history: []
            , agency_list: []
            , next_list: []
            , upperIdx: ''
            , upperFee: 0
            , availableFee: 0
            , lowerAvailableFee: 0
            , item_att: ''
            , files: []
            , logo_files: ''
            , modifyLogo: false
            , deleteIdxList: []
            , modal_mid: false
            , modal_delete_mid: false
            , modal_sid: false
            , account: ''
            , feeInfo: []
            , shopFeeType: ['QUICK', 'MONTHLY', 'D_DAY', 'D_0']
        }
    }
    , computed: {
        routeTab: function () {
            return `${this.item.shopName ? this.item.shopName : '영업점 상세' }`
        },
        history_list: function () {
            return this.items_history.filter(function (item) {
                return item
            })
        }
        , state_name: function () {

            let name = ''
            for (let i = 0; i < this.codes.status_agency.length; i++) {
                if (this.item.state == this.codes.status_agency[i].code) {
                    name = this.codes.status_agency[i].name
                    break
                }
            }

            return name
        }
        , upper_name: function () {
            let upper_name = ''
            if (this.item.type == 1) {
                upper_name = '본사'
            } else {
                if (!this.item.upperIdx) {
                    upper_name = '영업단 구분을 선택하세요'
                } else {
                    if (this.item.branchIdx) {
                        upper_name = this.item.branchName
                    }
                    if (this.item.distributorIdx) {
                        upper_name = ' ' + this.item.distributorName
                    }
                    if (this.item.agencyIdx) {
                        upper_name = ' ' + this.item.agencyName
                    }
                    if (this.item.resellerIdx) {
                        upper_name = ' ' + this.item.resellerName
                    }
                }
            }

            return upper_name
        }

    }
    , methods: {
        getData: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'branch/' + this.$route.params.idx
                    , multipart: true

                })
                if (result.success) {
                    this.item = result.data
                    this.files = this.item.attachments
                    if (this.item.logoImage) {
                        this.logo_files = []
                        this.logo_files[0] = {
                            'name': this.item.logoImageOriginName,
                            'path': this.item.logoImage
                        }
                    }
                    this.item.attachments = []
                    this.item.logoImage = []
                    this.item_origin = result.data
                } else {
                    this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , getBankList: async function () {
            try {
                this.$layout.onLoading()
                let result = await this.$Axios({
                    method: 'get'
                    , url: 'common/bank'
                })
                if (result.success) {
                    this.$set(this.codes, 'P13', result.data)
                    this.$layout.setNotify( {type: 'error', message: result.message})
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , getAgencyList: async function () {
            this.is_modal4 = true
        }
        , setFile: function (e) {
            console.log(this.item.attachments, 11)

            for (let i = 0; i < e.target.files.length; i++) {

                this.item.attachments.push(e.target.files[i])
            }
            console.log(this.item.attachments, 12)
        }
        , setLogoFile: function (e) {
            console.log(e)
            if (e.target.files[0]) {
                this.item.logoImage = []
                this.item.logoImage[0] = e.target.files[0]
                this.modifyLogoFile()
            }
            console.log(this.item.logoImage)
        }
        , modifyLogoFile: function () {
            this.item.isDeleteLogoImage = true
            this.logo_files = ''
        }
        , save: async function () {
            try {
                if (this.item.businessType == this.codes.P02_0) {
                    this.item.frontSSN = ''
                    this.item.backSSN = ''
                } else if (this.item.businessType == this.codes.P02_1) {
                    this.item.corporationNumber = ''
                    this.item.frontSSN = ''
                    this.item.backSSN = ''
                } else if (this.item.businessType == this.codes.P02_2) {
                    this.item.businessNumber = ''
                    this.item.corporationNumber = ''
                }
                if (!this.item.logoImage) {
                    delete this.item.logoImage
                }
                for (const [key, value] of Object.entries(this.item)) {
                    if (value === 'null' || value === '' || value === null) {
                        delete this.item[key]
                    }
                    if (typeof value === 'string') {
                        if (value.indexOf('undef') !== -1) {
                            delete this.item[key]
                        }
                    }
                }
                if(Number(this.item.type) !== 1) {
                    delete  this.item["settlementCycle"]
                }
                console.log(this.item,'item save')
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'put'
                    , url: 'branch/' + this.$route.params.idx
                    , data: this.item
                    , multipart: true
                })
                if (result.success) {
                    await this.getData()
                    this.$layout.setNotify( {type: 'success', message: result.message})
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , isPassword: function () {
            this.clear()
            this.is_modal = true
        }
        , isStatus: function () {
            this.clear()
            this.is_modal2 = true
        }
        , clear: function () {
            this.is_modal = false
            this.is_modal2 = false
            this.is_modal3 = false
            this.is_modal4 = false
            this.is_modal5 = false
            this.modal_mid = false
            this.modal_delete_mid = false
            this.modal_sid = false
        }
        , doStatus: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'put'
                    , url: 'branch/state/' + this.$route.params.idx
                    , data: {
                        state: this.item.state
                        , reason: this.item.reason
                    }
                })
                if (result.success) {
                    this.$layout.setNotify( {type: 'success', message: result.message})
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.clear()
                this.$layout.offLoading()
            }
        }
        , doPassword: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'put'
                    , url: 'branch/tempPassword/' + this.$route.params.idx
                })
                if (result.success) {
                    this.$layout.setNotify( {type: 'success', message: result.message})
                } else {
                    this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.clear()
                this.$layout.offLoading()
            }
        }
        , toList: function () {
            this.$layout.push( {name: 'AgencyList', not_query: true})
        }

        , daumPost: function () {
            this.is_post = true
        }
        , addPost: function (call) {

            this.$set(this.item, 'zipCode', call.zonecode)
            this.$set(this.item, 'address', call.address)

            this.is_post = false
        }
        , isDeleteNewFile: function (index) {
            this.$delete(this.item.attachments, index)
        }
        , isDeleteFile: function (index) {

            this.deleteIdxList.push(this.files[index].idx)
            this.$set(this.item, 'deleteIdxList', this.deleteIdxList)
            this.$delete(this.files, index)
        }
        , doFileDelete: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'post'
                    , url: ''
                    , data: this.item
                })
                if (result.success) {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                } else {
                    this.$layout.setNotify( {type: 'error', message: this.$language.common.error})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.clear()
                this.$layout.offLoading()
            }
        }
        , download: function (file) {
            console.log(file)
            console.log(file.path)
            this.is_modal5 = true
            this.item_att = file.path
        }
        , setUpper: function (item) {

            if (item.idx) {
                this.$set(this.item, 'upperIdx', item.idx)
                this.item.upperName = item.name
            }
            this.clear()
        }
        , getFee: async function (type, index) {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get'
                    , url: 'branch/modifyFee'
                    , data: {
                        adminIdx: this.item.idx
                        , shopFeeType: type
                    }
                })
                if (result.success) {
                    console.log(result.data, 'fee')
                    this.feeInfo.push(result.data)
                    this.feeInfo[index].type = type
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
        , resetUpper: function () {
            this.item.upperIdx = ''
            this.item.upperName = ''
        }
        , nextUpper: function (type, sub) {
            this.upperIdx = sub.idx
            this.getAgencyList()
        }
        , checkFee: function (type) {
            if(!this.feeInfo || this.feeInfo.length === 0 ){
                return
            }
            if(type === 'QUICK') {
                if(this.item.quickFee < this.feeInfo[0].minValue || this.item.quickFee > this.feeInfo[0].maxValue) {
                    this.$layout.setNotify( {
                        type: 'error'
                        , message: `배달비 수수료는 최소 ${this.feeInfo[0].minValue}%, 최대${this.feeInfo[0].maxValue}% 입력 가능합니다.`
                    })
                    this.item.quickFee = this.feeInfo[0].minValue
                }
            }
            if(type === 'MONTHLY') {
                if(this.item.monthlyFee < this.feeInfo[1].minValue || this.item.monthlyFee > this.feeInfo[1].maxValue) {
                    this.$layout.setNotify( {
                        type: 'error'
                        , message: `월세 수수료는 최소 ${this.feeInfo[1].minValue}%, 최대${this.feeInfo[1].maxValue}% 입력 가능합니다.`
                    })
                    this.item.monthlyFee = this.feeInfo[1].minValue
                }
            }
            if(type === 'D_DAY') {
                if(this.item.nonInstantFee < this.feeInfo[2].minValue || this.item.nonInstantFee > this.feeInfo[2].maxValue) {
                    this.$layout.setNotify( {
                        type: 'error'
                        , message: `D-DAY 정산 수수료는 최소 ${this.feeInfo[2].minValue}%, 최대${this.feeInfo[2].maxValue}% 입력 가능합니다.`
                    })
                    this.item.nonInstantFee = this.feeInfo[2].minValue
                }
            }
            if(type === 'D_0') {
                if(this.item.instantFee < this.feeInfo[3].minValue || this.item.instantFee > this.feeInfo[3].maxValue) {
                    this.$layout.setNotify( {
                        type: 'error'
                        , message: `즉시 정산 수수료는 최소 ${this.feeInfo[3].minValue}%, 최대${this.feeInfo[3].maxValue}% 입력 가능합니다.`
                    })
                    this.item.instantFee = this.feeInfo[3].minValue
                }
            }
        }
        , isModalMid: function () {
            this.clear()
            this.modal_mid = true
        }
        , isDeleteMid: function () {
            this.clear()
            this.modal_delete_mid = true
        }
        , doDeleteMid: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'delete'
                    , url: 'branch/mid/' + this.item.idx

                })
                if (result.success) {
                    await this.getData()
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
                this.clear()
            }
        }
        , doSaveMid: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'put'
                    , url: 'branch/mid/' + this.item.idx
                    , data: {
                      billKeyPaymentId: this.item.billKeyPaymentId
                        , billKeyPaymentPassword: this.item.billKeyPaymentPassword
                    }
                })
                if (result.success) {
                    await this.getData()
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
                this.clear()
            }
        }
        , isModalSid: function () {
            this.clear()
            this.modal_sid = true
        }
        , postSid: async function () {
            if(!this.item.sid) {
                this.$layout.setNotify( {type: 'error', message: '정산 대리업체 Merchant Id 입력해주세요.'})
                return
            }
            if(!this.item.key) {
                this.$layout.setNotify( {type: 'error', message: '정산 대리 업체 key 입력해주세요.'})
                return
            }
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'put'
                    , url: 'branch/keyIV/' + this.item.idx
                    , data: {
                        iv: this.item.iv
                        , key: this.item.key
                        , sid: this.item.sid
                    }
                })
                if (result.success) {
                    this.$layout.setNotify( {type: 'success', message: result.message})
                    this.modal_sid = false
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        }
    }
    , async created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
        await this.getData()
        await this.getBankList()
    }
    , watch: {
        'item.upperIdx': {
            immediate: false
            , handler: function () {
                for (let i = 0; i < this.shopFeeType.length; i++) {
                    this.getFee(this.shopFeeType[i], i)
                }
            }
        }

        , 'item.businessType': function () {
            this.item.businessNumber = this.item_origin.businessNumber
            this.item.corporationNumber = this.item_origin.corporationNumber
            this.item.frontSSN = this.item_origin.frontSSN
            this.item.backSSN = this.item_origin.backSSN
        },
        'item.quickType':
            {
                handler: function () {
                    if(String(this.item.quickType) === '0'){
                        this.item.autoSettlementFlag = true
                    }
                }
            },
    }
}
</script>

<style>
td {
    word-break: break-all;
}

.width-fee {
    width: 60px !important;
    text-align: right;
}
</style>